
import React from 'react';
import ReactDOM from 'react-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MuiAlert from '@material-ui/lab/Alert';
import config from '../../constant/config';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

import key from '../../constant/key'
import axios from 'axios';
import { GoogleReCaptcha, GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Chip, FormControl, Input, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select } from '@material-ui/core';
import MediaQuery from 'react-responsive';
import { ConfirmationNumber, ConfirmationNumberOutlined } from '@material-ui/icons';
import Notif from '../../components/Notif';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://google.com.com/">
                sukodono-mags  2020 v1.0-b202101131514
      </Link>
        </Typography>
    );
}

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class PesanNomor extends React.Component {


    constructor(props) {
        super(props);

        this.notif = React.createRef();
        this.state = {
            Nik: "",
            Whatsapp: "",
            InstansiID: "",
            JamKedatangan: "",
            Captcha: "",
            Layanan: [],

            JamKedatanganList: [],
            InstansiList: [],
            LayananList: [],
            loading: false,
            alertMessage: "",
            alertType: 'error',


        };
    }

    muatInstansiList = () => {
        const url = `${config.API_URL}/instansi`;

        this.setState({ LayananList: [], Layanan: [] })

        if (this.state.InstansiList.length > 0) return

        axios.get(url, {
            headers: { Authkey: '', }
        }).then(response => {
            this.setState({
                InstansiList: response.data.data,
            })
        })
    }

    muatLayananList = (instansiID = 0) => {
        const url = `${config.API_URL}/layanan?InstansiID=${instansiID}`;
        if (instansiID == this.state.InstansiID) return;

        this.setState({ LayananList: [], Layanan: [] })
        axios.get(url, {
            headers: { Authkey: '', }
        }).then(response => {
            this.setState({
                LayananList: response.data.data,
            })
            this.muatSisaNomorAntrian(instansiID)
        })
    }

    muatSisaNomorAntrian(instansiID) {
        // TODO : mulai dari sini
        const url = `${config.API_URL}/instansi/${instansiID}/cek-sisa-antrian`;

        axios.get(url, {
            headers: { Authkey: '', }
        }).then(response => {
            const jsonResponse = response.data
            console.log("muat jam");
            console.log(jsonResponse)

            if (!jsonResponse.result) {
                alert("Gagal Mendapatkan Jam ")
                return
            }

            const data = jsonResponse.data
            var sisa = 0;
            var listUntukDiipilih = [];

            sisa = data.Jam0809 - data.Jam0809Dipesan
            if (sisa > 0) listUntukDiipilih.push({ ID: "0809", Nama: "Jam 08.00 s/d 09.00 , tersisa " + sisa + " antrian." })

            sisa = data.Jam0910 - data.Jam0910Dipesan
            if (sisa > 0) listUntukDiipilih.push({ ID: "0910", Nama: "Jam 09.00 s/d 10.00 , tersisa " + sisa + " antrian." })

            sisa = data.Jam1011 - data.Jam1011Dipesan
            if (sisa > 0) listUntukDiipilih.push({ ID: "1011", Nama: "Jam 10.00 s/d 11.00 , tersisa " + sisa + " antrian." })

            sisa = data.Jam1112 - data.Jam1112Dipesan
            if (sisa > 0) listUntukDiipilih.push({ ID: "1112", Nama: "Jam 11.00 s/d 12.00 , tersisa " + sisa + " antrian." })

            sisa = data.Jam1213 - data.Jam1213Dipesan
            if (sisa > 0) listUntukDiipilih.push({ ID: "1213", Nama: "Jam 12.00 s/d 13.00 , tersisa " + sisa + " antrian." })

            sisa = data.Jam1314 - data.Jam1314Dipesan
            if (sisa > 0) listUntukDiipilih.push({ ID: "1314", Nama: "Jam 13.00 s/d 14.00 , tersisa " + sisa + " antrian." })

            sisa = data.Jam1415 - data.Jam1415Dipesan
            if (sisa > 0) listUntukDiipilih.push({ ID: "1415", Nama: "Jam 14.00 s/d 15.00 , tersisa " + sisa + " antrian." })

            sisa = data.Jam1516 - data.Jam1516Dipesan
            if (sisa > 0) listUntukDiipilih.push({ ID: "1516", Nama: "Jam 15.00 s/d 16.00 , tersisa " + sisa + " antrian." })

            if (listUntukDiipilih.length == 0) {
                //antrian tanpa jam
                sisa = data.MaxAntrianOnline - data.AntrianDipesan
                if (sisa > 0) listUntukDiipilih.push({ ID: "0", Nama: "Tersisa " + sisa + " antrian." })
            }

            if (listUntukDiipilih.length == 0) {
                this.setState({
                    JamKedatanganList: listUntukDiipilih,
                    alertMessage: "Antrian untuk " + data.Nama + " telah habis, silahkan ambil nomor antrian esok hari.",
                    alertType: "error"
                })
            }
            else {
                this.setState({
                    JamKedatangan: listUntukDiipilih[0].ID,
                    JamKedatanganList: listUntukDiipilih
                })
            }

        })
    }


    componentDidMount() {
        document.title = "Antrian Online Mini-MPP Sidoarjo";

        this.muatInstansiList()
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef) == null ? 0 : ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
        });
    }
    resetrecaptcha = () => {
        this.CaptchaComponent()
    };
    pesanNomor = () => {

        let today = new Date()
        let date = today.getDate()
        let month = today.getMonth() + 1
        let year = today.getFullYear()


        //validasi
        var validasi = ""
        if (this.state.Captcha == "") validasi += "\nCaptcha tidak ditemukan, mohon kontak kecamatan/admin"
        if (this.state.Nik.length != 16) validasi += "\nNIK tidak sesuai. "
        if (this.state.Whatsapp.length < 10) validasi += "\nNo Whatsapp tidak sesuai. "
        if (this.state.InstansiID == "") validasi += "\nTujuan belum terpilih. "
        if (this.state.JamKedatangan == "") validasi += "\nJam kedatangan belum dipilih"



        var ceksudahpernapesan = localStorage.getItem(
            this.state.Nik + "|" +
            this.state.InstansiID + "|" +
            `${year}${month}${date}`)
        if (ceksudahpernapesan != null) validasi += "\nNIK sudah memesan nomor untuk instansi tersebut. "


        if (validasi != "") {
            this.setState({ alertMessage: "Data anda kurang lengkap." + validasi, alertType: "error" })
            return
        }


        const url = `${config.API_URL}/antrian/pesan-nomor`;

        var formData = new FormData()
        formData.append("nik", this.state.Nik);
        formData.append("whatsapp", this.state.Whatsapp);
        formData.append("captcha", this.state.Captcha);
        formData.append("instansi_id", this.state.InstansiID);
        formData.append("jam_kedatangan", this.state.JamKedatangan);

        this.setState({ loading: true })
        axios({
            method: 'POST',
            url: url,
            data: formData,
            headers: {
                'Authkey': config.AUTHKEY,
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {

            if (response.data.result) {
                localStorage.setItem(
                    this.state.Nik + "|" +
                    this.state.InstansiID + "|" +
                    `${year}${month}${date}`,
                    true)

                this.setState({ alertMessage: response.data.message, alertType: "success", loading: false, })
                this.props.history.replace(`/DisplayQrcode/${response.data.data.Kodeunik}`)

            } else {
                this.muatSisaNomorAntrian(this.state.InstansiID)
                this.setState({ alertMessage: response.data.message, alertType: "error", loading: false, Captcha: "" }, this.resetrecaptcha())



            }

        })

    }
    CaptchaComponent = () => {
        if (this.state.Captcha != "") return <div />;
        var t = this
        const { executeRecaptcha } = useGoogleReCaptcha();
        const token = executeRecaptcha("pesanNomor").then((token) => {
            t.setState({ Captcha: token })
        });
        return <div />
    }

    render() {

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const hari = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]

        var bisapesannomor = false
        const now = new Date();
        const besok = new Date()
        besok.setDate(new Date().getDate() + 1)

        const jamawal = 10
        const jamakhir = 23//
        if (now.getHours() >= jamawal && now.getHours() < jamakhir) bisapesannomor = true
        if (besok.getDay() == 6 || besok.getDay() == 9) bisapesannomor = false

        if (this.props.location.search.includes("secretkey=d1b1b720267e8fe3e97bb4f860c2d2d1")) {
            bisapesannomor = true
        }

        const { classes } = this.props;

        return (
            <Container component="main" maxWidth="xs">

                <Notif ref={this.notif} />
                <MediaQuery minDeviceWidth={900}>
                    <img
                        style={{
                            zIndex: -100,
                            position: "fixed",
                            bottom: 0,
                            left: 20,
                            width: '40%'
                        }}
                        src="../../../image/drawkit-grape-pack-illustration-1.svg"
                        alt="antrian online Mini MPP Kecamatan Sukodono" />

                    <img
                        style={{
                            zIndex: -100,
                            position: "fixed",
                            top: 0,
                            right: 20,
                            width: '25%'
                        }}
                        src="../../../image/drawkit-grape-pack-illustration-12.svg"
                        alt="antrian online Mini MPP Kecamatan Sukodono" />

                </MediaQuery>

                <CssBaseline />
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <ConfirmationNumber />
                    </Avatar>
                    <Typography component="h1" variant="h5">Pesan Nomor Antrian</Typography>
                    <Typography component="h2" variant="h6">untuk {hari[besok.getDay()] + ", " + besok.getDate() + "/" + (besok.getMonth() + 1) + "/" + besok.getFullYear()}</Typography>

                    {
                        this.state.alertMessage !== "" &&
                        <Alert variant="filled" severity={this.state.alertType} style={{ marginTop: 10, marginBottom: 10 }}>
                            <Typography variant="body1">{this.state.alertMessage}</Typography>
                        </Alert>
                    }

                    {
                        !bisapesannomor ?
                            <Alert variant="filled" severity="error" style={{ marginTop: 40, marginBottom: 30 }}>
                                <Typography variant="body1">Pemesanan nomor bisa dilakukan pada pukul {jamawal}.00 hingga {jamakhir}.00 di hari Minggu s/d Kamis untuk hari berikutnya</Typography>
                            </Alert> :

                            <GoogleReCaptchaProvider reCaptchaKey="6LdUnyAaAAAAABAGI5r5bJSwB0oyvKB8k27iTLDt" >
                                <form className={classes.form} noValidate>
                                    <this.CaptchaComponent />
                                    <TextField
                                        disabled={this.state.loading}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Nik"
                                        type="number"
                                        onChange={(ev) => { this.setState({ "Nik": ev.target.value }); }}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                this.pesanNomor()
                                            }
                                        }}
                                        autoFocus
                                    />
                                    <TextField
                                        disabled={this.state.loading}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="No. Whatsapp"
                                        type="number"
                                        onChange={(ev) => { this.setState({ "Whatsapp": ev.target.value }); }}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                this.pesanNomor()
                                            }
                                        }}

                                    />

                                    <TextField
                                        select fullWidth
                                        label="Instansi"
                                        value={this.state.InstansiID == null ? 0 : this.state.InstansiID}
                                        onChange={(event) => {
                                            this.setState({
                                                InstansiID: event.target.value,
                                                JamKedatanganList: [],
                                                JamKedatangan: "0"
                                            }, this.muatLayananList(event.target.value))
                                        }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        margin="normal"

                                    >
                                        <option value={0} >Pilih Instansi</option>
                                        {this.state.InstansiList.map((option) => (
                                            <option key={option.ID} value={option.ID}>
                                                {option.Nama}
                                            </option>
                                        ))}
                                    </TextField>

                                    <FormControl fullWidth variant='outlined' margin="normal" className={classes.formControl}>
                                        <InputLabel
                                            ref={ref => {
                                                this.InputLabelRef = ref;
                                            }}
                                        >Layanan</InputLabel>
                                        <Select
                                            multiple
                                            variant="outlined"
                                            value={this.state.Layanan}
                                            onChange={(event) => {
                                                console.log(event.target.value);
                                                var persyaratan = "Persyaratan:"
                                                this.state.LayananList.forEach(element => {
                                                    if (event.target.value.includes(element.ID)) {
                                                        persyaratan +=
                                                            "Pelayanan " + element.Nama + "<br/>" +
                                                            element.Persyaratan.replace(/(?:\r\n|\r|\n)/g, '<br>') + "<br/><br/>"
                                                    }
                                                });
                                                this.notif.current.handleOpen("warning", persyaratan)
                                                this.setState({
                                                    Layanan: event.target.value,
                                                    //alertMessage: "info layanan ini",
                                                    //alertType: "info"
                                                })

                                            }}
                                            input={<OutlinedInput labelWidth={this.state.labelWidth} />}
                                            renderValue={(selected) => {
                                                var selectedText = ""
                                                this.state.LayananList.forEach(l => {
                                                    if (selected.includes(l.ID)) selectedText += l.Nama + ", "
                                                })
                                                return selectedText
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                            }}
                                        >

                                            {this.state.LayananList.map((layanan) => (
                                                <MenuItem key={layanan.ID.toString()} value={layanan.ID}>
                                                    <Checkbox checked={this.state.Layanan.indexOf(layanan.ID) > -1} />
                                                    <ListItemText primary={layanan.Nama} />
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                    {this.state.JamKedatanganList.length == 0 ? <div /> :
                                        <TextField
                                            select fullWidth
                                            label="Rencana Kedatangan"
                                            value={this.state.JamKedatanganList == null ? "0" : this.state.JamKedatangan}
                                            SelectProps={{ native: true, }}
                                            variant="outlined"
                                            margin="normal"
                                            onChange={(event) => {
                                                this.setState({ JamKedatangan: event.target.value })
                                            }}
                                        >
                                            {this.state.JamKedatanganList.map((option) => (
                                                <option key={option.ID} value={option.ID}>
                                                    {option.Nama}
                                                </option>
                                            ))}
                                        </TextField>
                                    }

                                    <Button
                                        disabled={this.state.loading || this.state.JamKedatanganList.length == 0}
                                        onClick={this.pesanNomor}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >Pesan Nomor</Button>

                                </form>
                            </GoogleReCaptchaProvider>
                    }
                </Paper>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        );
    }
}


PesanNomor.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PesanNomor);