import { Button, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import React, { forwardRef, useImperativeHandle } from 'react';

const Notif = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("primary");
  const [message, setMessage] = React.useState("Halo, apa kabar");


  const handleClose = (event, reason) => {
    setOpen(false);
  };
  const handleOpen = (type, message) => {

    setType(type)
    setMessage(message)
    if (!open) setOpen(true)
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen,
      handleClose: handleClose
    }
  })
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}>
      <Alert variant='filled' onClose={handleClose} severity={type}>
        <div dangerouslySetInnerHTML={{ __html: message }}></div>
      </Alert>
    </Snackbar>
  );
});
export default Notif;