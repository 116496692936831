import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import PropTypes from 'prop-types'
import { fade } from '@material-ui/core/styles/colorManipulator'



import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { VolumeUp, PlusOne } from '@material-ui/icons'

import config from '../../constant/config'
import key from '../../constant/key'
import { IconButton } from '@material-ui/core'

import { terbilang, pisahHurufAngka, suaraAntrian } from '../../Helper/Angka'
import MediaQuery from 'react-responsive'

var Sound = require('react-sound').default;
const styles = theme => ({
    toolbarButtons: {
        marginLeft: 'auto',
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    btnPlay: {
        marginLeft: theme.spacing(1),
    },
    rowSelected: {
        backgroundColor: fade(theme.palette.secondary.main, 0.5)
    }
})

class PanggilNomor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ws: null,
            daftarAntrian: [
            ],
            mainkanSuara: false,
            listMp3: []
        }
    }
    _tambahAntrian = (antrianBaru) => {

        var listSemua = this.state.daftarAntrian
        listSemua.push(antrianBaru)

        var mainkanSuara = this.state.mainkanSuara
        if (mainkanSuara)
            this.setState({ daftarAntrian: listSemua })
        else
            this.setState({ daftarAntrian: listSemua }, () => { this._suaraAntrian() })


    }
    _suaraAntrian = () => {
        const { daftarAntrian } = this.state
        if (daftarAntrian.length == 0) return

        var antrianTerpilih = daftarAntrian[0]

        var nomor = antrianTerpilih.Nomor == 0 ? antrianTerpilih.NomorOnline : antrianTerpilih.Nomor

        var antrian = [
            "_antrian",//  'jeda025',
            antrianTerpilih.KodeInstansi.toLowerCase()
        ]
        if (antrianTerpilih.Nomor == 0) antrian.push("o")
        antrian = antrian.concat(terbilang(nomor + "").split(' '))


        var suara = antrian.concat([
            '_di_loket', antrianTerpilih.SuaraLoket, 'jeda1'
        ])
        console.log(suara)
        this.setState({ mainkanSuara: true, listMp3: suara })



    }
    _websocketConnect = () => {
        var ws = new WebSocket(config.WS_URL + "?authkey=" + config.AUTHKEY)
        let that = this // cache the this
        var connectInterval

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component")

            this.setState({ ws: ws })

            that.timeout = 250 // reset timer to 250 on open of websocket connection 
            clearTimeout(connectInterval) // clear Interval on on open of websocket connection
        }
        ws.onmessage = (e) => {
            const message = JSON.parse(e.data)
            console.log("receiving")
            console.log(message)

            if (message.type === key.WS_PANGGILAN)
                this._tambahAntrian(message.data)
        }

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            )

            that.timeout = that.timeout + that.timeout //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)) //call check function after timeout
        }

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            )

            ws.close()
        }
    }

    componentDidMount() {
        this._websocketConnect()
    }


    pad = (num, size) => {
        var s = "000000000" + num
        return s.substr(s.length - size)
    }
    jam = (stringDatetime) => {
        var dt = new Date(stringDatetime)
        return dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds()
    }

    render() {
        const { classes } = this.props
        const { daftarAntrian } = this.state

        return <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <VolumeUp className={classes.icon} />
                    <Typography variant="h6" color="inherit" noWrap>
                        Mini MPP Kecamatan Sukodono
                    </Typography>
                </Toolbar>
            </AppBar>

            <MediaQuery minDeviceWidth={900}>
                <img
                    style={{
                        zIndex: -100,
                        position: "fixed",
                        bottom: 0,
                        right: 20,
                        marginRight: -100,
                        width: 300
                    }}
                    src="../../../image/drawkit-grape-pack-illustration-16.svg"
                    alt="antrian online Mini MPP Kecamatan Sukodono" />

                <img
                    style={{
                        zIndex: -100,
                        position: "fixed",
                        bottom: 0,
                        marginLeft: -10,
                        width: 290
                    }}
                    src="../../../image/drawkit-grape-pack-illustration-5.svg"
                    alt="antrian online Mini MPP Kecamatan Sukodono" />
            </MediaQuery>
            <main>
                <Container className={classes.cardGrid} maxWidth="lg">
                    {/* End hero unit */}
                    <Grid container spacing={3} justify={"center"}>
                        <Grid item xs={12} sm={10} md={10}>

                            <Typography variant="h6" component="h1" align='left' gutterBottom>
                                Antrian
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Nomor</TableCell>
                                            <TableCell align="center">Loket</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {daftarAntrian.map((item, index) => (
                                            <TableRow key={item.ID} className={index === 0 && this.state.listMp3.length % 2 === 0 ? classes.rowSelected : null}>
                                                <TableCell component="th" scope="row" align="center">
                                                    <Typography variant={index === 0 ? "h2" : "h6"} color="inherit" noWrap>
                                                        {item.KodeInstansi + (item.Nomor == 0 ? "O" : "") + "-" + this.pad(item.Nomor == 0 ? item.NomorOnline : item.Nomor, 3)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography variant={index === 0 ? "h2" : "h6"} color="inherit" noWrap>
                                                        {item.NamaLoket}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {daftarAntrian.length === 0 && <TableRow>
                                            <TableCell align="center" colSpan={2}>Tidak ada data</TableCell>
                                        </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <IconButton onClick={() => this._suaraAntrian()} color="primary"  >
                                <VolumeUp fontSize="small" />
                            </IconButton>

                        </Grid>
                    </Grid>
                </Container>
            </main>
            <Sound
                url={"../../../suara_antrian/" + (this.state.listMp3.length > 0 ? this.state.listMp3[0] : "") + ".mp3"}
                playStatus={this.state.mainkanSuara ? Sound.status.PLAYING : Sound.status.STOPPED}
                //onLoading={this.handleSongLoading}
                //onPlaying={this.handleSongPlaying}
                onFinishedPlaying={() => {
                    var listMp3 = this.state.listMp3
                    listMp3.shift()
                    if (listMp3.length == 0) {
                        this.setState({ listMp3: listMp3, mainkanSuara: false })

                        //hapus antrian yang baruselesai
                        var daftarAntrian = this.state.daftarAntrian
                        daftarAntrian.shift()
                        this.setState({ daftarAntrian: daftarAntrian })
                        this._suaraAntrian()

                    } else {
                        this.setState({ listMp3: listMp3, mainkanSuara: true })
                    }
                }}
            />

        </React.Fragment>
    }
}



PanggilNomor.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(PanggilNomor)