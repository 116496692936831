import React from 'react';
import AppBar from '@material-ui/core/AppBar';

import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import UbahIcon from '@material-ui/icons/Visibility';
import TambahIcon from '@material-ui/icons/Add';

import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';


import key from '../../constant/key';
import { Button, FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import axios from 'axios';

import config from '../../constant/config'
import MainAppbar from '../../components/MainAppbar';
import { grey } from '@material-ui/core/colors';
import Notif from '../../components/Notif';
import ConfirmDialog from '../../components/ConfirmDialog'
import MediaQuery from 'react-responsive';

const styles = theme => ({
  table: {
    width: '100%'
  },
  tablecell: {
    verticalAlign: 'top',
    whiteSpace: 'pre-wrap'
  },
  mainContaner: {
    paddingTop: 15,
    flexGrow: 1
  },
  form: {
    width: '100%'
  },
  title: {
    color: '#666'
  },
  dialogAction: {
    marginRight: 30,
    marginTop: 10,
    marginBottom: 10,
  }

});

class MasterLayanan extends React.Component {

  constructor(props) {
    super(props);

    this.notif = React.createRef();

    this.state = {
      selectedData: {
        ID: 0,
        Nama: "",
        InstansiID: 0,
        InstansiNama: "",
        Persyaratan: "",
        Status: 1,
      },
      data: [],
      listInstansi: [],
      formVisibility: false,
      tanyaHapus: false,
    }
  }


  _inisialisasiList = () => {
    this.ambilLayananList()
    this.instansiList()
  }

  ambilLayananList = () => {
    const url = `${config.API_URL}/layanan`;
    axios.get(url, {
      headers: {
        Authkey: this.state.userData.Authkey,
      }
    }).then(response => {
      this.setState({
        data: response.data.data,
      })
    })
  }

  instansiList = () => {
    const url = `${config.API_URL}/antrian/instansi-list`;
    axios.get(url, {
      headers: {
        Authkey: this.state.userData.Authkey,
      }
    }).then(response => {

      this.setState({
        listInstansi: response.data.data,
        selectedData: { ...this.state.selectedData, InstansiID: this.state.userData.InstansiID }
      })
    })
  }

  componentWillUnmount() {
  }

  componentDidMount() {

    var userData = localStorage.getItem(key.USER_SESSION)
    if (userData != null) {
      this.setState(
        { userData: JSON.parse(userData) },
        () => this._inisialisasiList()
      )
    } else {
      this.props.history.push('/')
    }
  }

  clearForm = (notifbatal = true) => {

    this.setState({
      formVisibility: false,
      selectedData: {
        ID: 0,
        Nama: "",
        InstansiID: this.state.userData.InstansiID,
        InstansiNama: "",
        Persyaratan: "",
        Status: 1,
      },
    })

    if (notifbatal) this.notif.current.handleOpen("warning", "Batal menyimpan data ")
  }

  saveButton = () => {
    var url = `${config.API_URL}/layanan`;

    var formData = new FormData()
    var item = this.state.selectedData
    for (var key in item) {
      formData.append(key, item[key]);
    }
    if (item.ID != 0) url += `/${item.ID}`
    axios({
      method: this.state.selectedData.ID == 0 ? 'POST' : 'PUT',
      url: url,
      data: formData,
      headers: {
        'Authkey': this.state.userData.Authkey,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {


        if (response.status === 200 && response.data.result === true) {
          this.notif.current.handleOpen("success", response.data.message)
          this.ambilLayananList()
          this.clearForm(false)
          this.setState({ formVisibility: false })

        } else if (response.status === 200 && response.data.result === false) {
          this.notif.current.handleOpen("error", response.data.message)

        } else {
          this.notif.current.handleOpen("error", "Koneksi dengan server gagal, periksa koneksi dan coba beberapa saat kemudian.")
        }

      })
  }

  render() {
    const { classes } = this.props;
    return <React.Fragment>
      <MainAppbar />

      <MediaQuery minDeviceWidth={900}>
        <img
          style={{
            zIndex: -100,
            position: "fixed",
            bottom: 0,
            right: 20,
            marginRight: -100,
            width: 300
          }}
          src="../../../image/drawkit-grape-pack-illustration-16.svg"
          alt="antrian online Mini MPP Kecamatan Sukodono" />

        <img
          style={{
            zIndex: -100,
            position: "fixed",
            bottom: 0,
            marginLeft: -10,
            width: 290
          }}
          src="../../../image/drawkit-grape-pack-illustration-5.svg"
          alt="antrian online Mini MPP Kecamatan Sukodono" />
      </MediaQuery>
      <Notif ref={this.notif} />
      <Container className={classes.mainContaner} maxWidth="md">
        <h1 className={classes.title}>Daftar Layanan</h1>

        <Grid container spacing={3} >
          <Grid item sm={12} xs={12} md={12}>
            <TableContainer component={Paper} style={{ width: '100%' }}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <IconButton color="primary" aria-label="Tambah Data" variant="contained" component="span"
                        onClick={() => { this.setState({ formVisibility: true }) }}>
                        <TambahIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>Nama</TableCell>
                    <TableCell>Instansi</TableCell>
                    <TableCell>Persyaratan</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.data.map((row) => (
                    <TableRow key={row.ID}>
                      <TableCell className={classes.tablecell}>

                        <IconButton color="primary" aria-label="Ubah Data" component="span"
                          onClick={() => {
                            this.setState({
                              formVisibility: true,
                              selectedData: row
                            })
                          }}>
                          <UbahIcon />
                        </IconButton>

                      </TableCell>
                      <TableCell component="th" scope="row" className={classes.tablecell}>
                        {row.Nama}
                      </TableCell>
                      <TableCell className={classes.tablecell}>{row.InstansiNama}</TableCell>
                      <TableCell className={classes.tablecell} style={{ width: '40%' }}>{row.Persyaratan}</TableCell>
                      <TableCell className={classes.tablecell}>{row.Status == 1 ? "Aktif" : "Inaktif"}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>


          <Dialog
            open={this.state.formVisibility}
            maxWidth="sm"
            fullWidth
            onClose={() => {
              this.setState({ formVisibility: false })
            }}
          >
            <DialogTitle>{this.state.selectedData.ID == 0 ? "Buat" : "Ubah"} data</DialogTitle>
            <DialogContent>

              <form className={classes.form} noValidate autoComplete="off">
                <Grid item xs={12} container spacing={2}
                  direction="row"
                  wrap='wrap'
                  alignItems='flex-start'
                  alignContent='flex-start'
                >

                  <Grid item xs={12} >
                    <TextField fullWidth
                      label="Nama"
                      value={this.state.selectedData.Nama}
                      onChange={(event) => {
                        this.setState({ selectedData: { ...this.state.selectedData, Nama: event.target.value } })

                      }}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} >
                    <TextField fullWidth multiline rowsMax={10} rows={10}
                      label="Keterangan & Persyaratan"
                      value={this.state.selectedData.Persyaratan}
                      onChange={(event) => {
                        this.setState({ selectedData: { ...this.state.selectedData, Persyaratan: event.target.value } })
                      }}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} >
                    <TextField
                      disabled={this.state.userData != null && this.state.userData.RoleID != 1}
                      select fullWidth
                      label="Instansi"
                      value={this.state.selectedData.InstansiID == null ? 0 : this.state.selectedData.InstansiID}
                      onChange={(event) => {
                        this.setState({ selectedData: { ...this.state.selectedData, InstansiID: event.target.value } })

                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option value={0} >Pilih Instansi</option>
                      {this.state.listInstansi.map((option) => (
                        <option key={option.ID} value={option.ID}>
                          {option.Nama}
                        </option>
                      ))}
                    </TextField>
                  </Grid>


                  <Grid item xs={12} >
                    <TextField
                      select fullWidth
                      label="Status"
                      value={this.state.selectedData.Status == null ? 0 : this.state.selectedData.Status}
                      onChange={(event) => {
                        this.setState({ selectedData: { ...this.state.selectedData, Status: event.target.value } })

                      }}
                      SelectProps={{ native: true, }}
                      variant="outlined"
                    >
                      <option value={1} >Aktif</option>
                      <option value={0} >Inaktif</option>
                    </TextField>
                  </Grid>

                </Grid>
              </form>
            </DialogContent>

            <DialogActions className={classes.dialogAction}>
              <Button
                style={{ marginRight: 10 }}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<CheckIcon />}
                onClick={this.saveButton}
              >{this.state.selectedData.ID == 0 ? "Tambah" : "Ubah"} Data</Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<CancelIcon />}
                onClick={this.clearForm}
              >Batal</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>


      <ConfirmDialog
        title={`Hapus data ` + this.state.selectedData.Nama + "?"}
        open={this.state.tanyaHapus}
        setOpen={(val) => this.setState({ tanyaHapus: val })}
        onConfirm={() => this.hapusData(this.state.selectedData.ID)}
        onClose={() => {
          this.clearForm(false)
        }}
      />
    </React.Fragment >
  }
}



MasterLayanan.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MasterLayanan);