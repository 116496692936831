
import React from 'react';
import ReactDOM from 'react-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MuiAlert from '@material-ui/lab/Alert';
import config from '../../constant/config';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

import key from '../../constant/key'
import axios from 'axios';
import { GoogleReCaptcha, GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Chip, FormControl, Input, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select } from '@material-ui/core';
import MediaQuery from 'react-responsive';
import { ConfirmationNumber, ConfirmationNumberOutlined } from '@material-ui/icons';
import ReactToPrint from 'react-to-print';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://google.com.com/">
                sukodono-mags  2020 v1.0-b202101131514
      </Link>
        </Typography>
    );
}

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),

    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    maincontainer: {
        textAlign: 'center',
        backgroundColor: 'red'
    }
});

class DisplayQrcode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            antrian: null,
        }
    }


    componentDidMount() {
        this.DisplayQrcode(this.props.match.params.kodeunik)
    }
    DisplayQrcode = (kodeunik = '') => {
        const url = `${config.API_URL}/antrian/cek-kodeunik/${kodeunik}`;

        this.setState({ LayananList: [], Layanan: [] })
        axios.get(url, {
            headers: { Authkey: '', }
        }).then(response => {
            var data = response.data
            if (data.result) {
                this.setState({ antrian: data.data })
            }
            console.log(data.data)
        })

    }

    pad = (num, size) => {
        var s = "000000000" + num;
        return s.substr(s.length - size);
    }
    displayTanggal = () => {
        if (this.state.antrian == null) return ""
        const rencanaKedatangan = this.state.antrian.RencanaKedatangan
        const tanggal = this.state.antrian.Tanggal

        if (rencanaKedatangan.length == 4) {
            return tanggal.split("T")[0] + " " + rencanaKedatangan.substring(0, 2) + ":00 s/d " + rencanaKedatangan.substring(2, 4) + ":00";

        }
        return tanggal.split("T")[0];


    }

    render() {

        const { classes } = this.props;

        const antrian = this.state.antrian
        return (
            <Container component="main" maxWidth="xs">

                <MediaQuery minDeviceWidth={900}>
                    <img
                        style={{
                            zIndex: -100,
                            position: "fixed",
                            bottom: 0,
                            left: 20,
                            width: '40%'
                        }}
                        src="../../../image/drawkit-grape-pack-illustration-1.svg"
                        alt="antrian online Mini MPP Kecamatan Sukodono" />

                    <img
                        style={{
                            zIndex: -100,
                            position: "fixed",
                            top: 0,
                            right: 20,
                            width: '25%'
                        }}
                        src="../../../image/drawkit-grape-pack-illustration-12.svg"
                        alt="antrian online Mini MPP Kecamatan Sukodono" />

                </MediaQuery>

                <CssBaseline />

                <Paper className={classes.paper} ref={el => (this.qrPaper = el)}>
                    <Avatar className={classes.avatar}>
                        <ConfirmationNumber />
                    </Avatar>
                    {
                        antrian == null ? <p>memuat....</p> :
                            <Grid container
                                direction="column"
                                justify="center"
                                alignItems="center">

                                <Typography component="h1" variant="h5">Antrian Mini-MPP</Typography>
                                <Typography component="h2" variant="h6">{antrian.NamaInstansi}</Typography>

                                <img width={"100%"} style={{ maxWidth: 200, }} src={`${config.API_URL}/qrcode/${antrian.Kodeunik}.png`}></img>

                                <h2 style={{ margin: 0, fontSize: 28 }}>{antrian.KodeInstansi + "O-" + this.pad(antrian.NomorOnline, 3)}</h2>
                                <p style={{ width: '100%', textAlign: 'center', margin: 0 }}>
                                    {antrian.Nik}
                                </p><p style={{ width: '100%', textAlign: 'center', margin: 0 }}>
                                    {this.displayTanggal()}
                                </p>

                                <p>Silahkan <ReactToPrint
                                    trigger={() => <a href="#">cetak</a>}
                                    content={() => this.qrPaper}
                                /> / screenshot / foto qrcode ini untuk dibawa menuju Mini MPP Kec. Sukodono untuk di scan di <b>mesin konfirmasi kedatangan</b></p>
                            </Grid>
                    }
                </Paper>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        );
    }
}


DisplayQrcode.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DisplayQrcode);