
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MuiAlert from '@material-ui/lab/Alert';
import config from '../../constant/config';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

import key from '../../constant/key'
import MediaQuery from 'react-responsive';
import { Paper } from '@material-ui/core';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://google.com.com/">
                sukodono-mags  2020 v1.0-b202106081850
      </Link>
        </Typography>
    );
}

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class SignIn extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false,
            alertMessage: "",
            alertType: 'error'
        };
    }

    loginToServer = () => {
        const url = `${config.API_URL}/pengguna/login`;

        var param = new FormData()
        param.append("username", this.state.username,)
        param.append("password", this.state.password)

        this.setState({ loading: true });
        fetch(url, {
            method: 'POST', headers: {},
            body: param
        })
            .then(res => res.json())
            .then(res => {
                this.setState({ loading: false });
                if (!res.result) {
                    this.setState({ alertMessage: res.message, alertType: "error" })
                } else {
                    this.setState({ alertMessage: "Memuat sistem,... ", alertType: 'success', loading: true })
                    localStorage.setItem(key.USER_SESSION, JSON.stringify(res.data))

                    this.props.history.push('/PanggilNomor')
                }
            })
            .catch(error => {
                console.log(error)
            });
    }
    componentDidMount() {
        var userData = localStorage.getItem(key.USER_SESSION)
        console.log(userData)
        if (userData != null) {
            this.props.history.push('/PanggilNomor')
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <Container component="main" maxWidth="xs">

                <MediaQuery minDeviceWidth={900}>
                    <img
                        style={{
                            zIndex: -100,
                            position: "fixed",
                            bottom: 0,
                            left: 20,
                            height: '100%'
                        }}
                        src="../../../image/drawkit-grape-pack-illustration-17.svg"
                        alt="antrian online Mini MPP Kecamatan Sukodono" />

                    <img
                        style={{
                            zIndex: -100,
                            position: "fixed",
                            top: '25%',
                            right: 20,
                            width: '25%'
                        }}
                        src="../../../image/drawkit-grape-pack-illustration-7.svg"
                        alt="antrian online Mini MPP Kecamatan Sukodono" />

                </MediaQuery>

                <CssBaseline />
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                </Typography>

                    {
                        this.state.alertMessage !== "" &&
                        <Alert variant="filled" severity={this.state.alertType} style={{ marginTop: 10, marginBottom: 10 }}>
                            {this.state.alertMessage}
                        </Alert>
                    }

                    <form className={classes.form} noValidate>
                        <TextField
                            disabled={this.state.loading}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Username"
                            autoComplete="email"
                            onChange={(ev) => { this.setState({ "username": ev.target.value }); }}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    this.loginToServer()
                                }
                            }}
                            autoFocus
                        />
                        <TextField
                            disabled={this.state.loading}
                            variant="outlined"
                            margin="normal"
                            label="Password"
                            type="password"
                            fullWidth
                            onChange={(ev) => { this.setState({ "password": ev.target.value }); }}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    this.loginToServer()
                                }
                            }}
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            onClick={this.loginToServer}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={this.state.loading}
                        >
                            Masuk
                </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                    </Link>
                            </Grid>
                            {/*
                    <Grid item>
                    <Link href="#" variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>
                    </Grid>
                    */}
                        </Grid>
                    </form>
                </Paper>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        );
    }
}


SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);