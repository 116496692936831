import React from 'react';
import AppBar from '@material-ui/core/AppBar';

import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import UbahIcon from '@material-ui/icons/Visibility';
import TambahIcon from '@material-ui/icons/Add';

import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';


import key from '../../constant/key';
import { Button, FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import axios from 'axios';

import config from '../../constant/config'
import MainAppbar from '../../components/MainAppbar';
import { grey } from '@material-ui/core/colors';
import Notif from '../../components/Notif';
import ConfirmDialog from '../../components/ConfirmDialog'
import MediaQuery from 'react-responsive';

const styles = theme => ({
  table: {
    width: '100%'
  },
  mainContaner: {
    paddingTop: 15,
    flexGrow: 1
  },
  form: {
    width: '100%'
  },
  title: {
    color: '#666'
  },
  dialogAction: {
    marginRight: 30,
    marginTop: 10,
    marginBottom: 10,
  }

});

class MasterUser extends React.Component {

  constructor(props) {
    super(props);

    this.notif = React.createRef();

    this.state = {
      selectedData: {
        ID: 0,
        Nama: "",
        Username: "",
        RoleID: 2,
        InstansiID: null,
        NamaInstansi: "",
        KodeInstansi: "",
        Password: '',
      },
      data: [],
      listInstansi: [],
      showPassword: false,
      formVisibility: false,
      tanyaHapus: false,
    }
  }


  _inisialisasiList = () => {
    this.userList()
    this.instansiList()
  }

  instansiList = () => {
    const url = `${config.API_URL}/antrian/instansi-list`;
    axios.get(url, {
      headers: {
        Authkey: this.state.userData.Authkey,
      }
    }).then(response => {
      this.setState({
        listInstansi: response.data.data,
      })
    })
  }
  userList = () => {
    const url = `${config.API_URL}/pengguna`;
    axios.get(url, {
      headers: {
        Authkey: this.state.userData.Authkey,
      }
    }).then(response => {
      this.setState({
        data: response.data.data,
      })
    })
  }

  createData = (name, calories, fat, carbs, protein) => {
    return { name, calories, fat, carbs, protein };
  }

  componentWillUnmount() {
  }

  componentDidMount() {

    var userData = localStorage.getItem(key.USER_SESSION)
    if (userData != null) {
      this.setState(
        { userData: JSON.parse(userData) },
        () => this._inisialisasiList()
      )
    } else {
      this.props.history.push('/')
    }
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  clearForm = (notifbatal = true) => {

    this.setState({
      formVisibility: false,
      selectedData: {
        ID: 0,
        Nama: "",
        Username: "",
        RoleID: 2,
        InstansiID: null,
        NamaInstansi: "",
        KodeInstansi: "",
        Password: '',
      },
    })

    if (notifbatal) this.notif.current.handleOpen("warning", "Batal menyimpan data ")
  }

  saveButton = () => {
    const url = `${config.API_URL}/pengguna`;

    var formData = new FormData()
    var item = this.state.selectedData
    for (var key in item) {
      formData.append(key, item[key]);
    }
    axios({
      method: this.state.selectedData.ID == 0 ? 'POST' : 'PUT',
      url: url,
      data: formData,
      headers: {
        'Authkey': this.state.userData.Authkey,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {


        if (response.status === 200 && response.data.result === true) {
          this.notif.current.handleOpen("success", response.data.message)
          this.userList()
          this.clearForm(false)
          this.setState({ formVisibility: false })

        } else if (response.status === 200 && response.data.result === false) {
          this.notif.current.handleOpen("error", response.data.message)

        } else {
          this.notif.current.handleOpen("error", "Koneksi dengan server gagal, periksa koneksi dan coba beberapa saat kemudian.")
        }

      })
  }

  hapusData = (id = 0) => {
    const url = `${config.API_URL}/pengguna/${id}`;
    axios({
      method: 'DELETE',
      url: url,
      headers: {
        'Authkey': this.state.userData.Authkey,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.status === 200 && response.data.result === true) {
          this.notif.current.handleOpen("success", response.data.message)
          this.userList()

        } else if (response.status === 200 && response.data.result === false) {
          this.notif.current.handleOpen("error", response.data.message)

        } else {
          this.notif.current.handleOpen("error", "Koneksi dengan server gagal, periksa koneksi dan coba beberapa saat kemudian.")
        }

      })
  }

  render() {
    const { classes } = this.props;
    return <React.Fragment>
      <MainAppbar />

      <MediaQuery minDeviceWidth={900}>
        <img
          style={{
            zIndex: -100,
            position: "fixed",
            bottom: 0,
            right: 20,
            marginRight: -100,
            width: 300
          }}
          src="../../../image/drawkit-grape-pack-illustration-16.svg"
          alt="antrian online Mini MPP Kecamatan Sukodono" />

        <img
          style={{
            zIndex: -100,
            position: "fixed",
            bottom: 0,
            marginLeft: -10,
            width: 290
          }}
          src="../../../image/drawkit-grape-pack-illustration-5.svg"
          alt="antrian online Mini MPP Kecamatan Sukodono" />
      </MediaQuery>
      <Notif ref={this.notif} />
      <Container className={classes.mainContaner} maxWidth="md">
        <h1 className={classes.title}>Daftar Pengguna</h1>

        <Grid container spacing={3} >
          <Grid item sm={12} xs={12} md={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <IconButton color="primary" aria-label="Tambah Data" variant="contained" component="span"
                        onClick={() => { this.setState({ formVisibility: true }) }}>
                        <TambahIcon />
                      </IconButton>
                      Nama</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Instansi</TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.data.map((row) => (
                    <TableRow key={row.ID}>

                      <TableCell component="th" scope="row">
                        <IconButton color="primary" aria-label="Ubah Data" component="span"
                          onClick={() => {
                            this.setState({
                              formVisibility: true,
                              selectedData: row
                            })
                          }}>
                          <UbahIcon />
                        </IconButton>
                        {row.Nama}
                      </TableCell>
                      <TableCell>{row.Username}</TableCell>
                      <TableCell>{row.NamaInstansi}</TableCell>
                      <TableCell>
                        <IconButton color="secondary" aria-label="Hapus Data" component="span"
                          onClick={() => {
                            this.setState({ tanyaHapus: true, selectedData: row })

                            //this.hapusData(row.ID)
                          }}>
                          <DeleteIcon />
                        </IconButton>

                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>


          <Dialog
            open={this.state.formVisibility}
            maxWidth="sm"
            fullWidth
            onClose={() => {
              this.setState({ formVisibility: false })
            }}
          >
            <DialogTitle>{this.state.selectedData.ID == 0 ? "Buat" : "Ubah"} data pengguna</DialogTitle>
            <DialogContent>

              <form className={classes.form} noValidate autoComplete="off">
                <Grid item xs={12} container spacing={2}
                  direction="row"
                  wrap='wrap'
                  alignItems='flex-start'
                  alignContent='flex-start'
                >

                  <Grid item xs={12} >
                    <TextField fullWidth
                      label="Nama"
                      value={this.state.selectedData.Nama}
                      onChange={(event) => {
                        this.setState({ selectedData: { ...this.state.selectedData, Nama: event.target.value } })

                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <TextField fullWidth
                      label="Username"
                      value={this.state.selectedData.Username}
                      onChange={(event) => {
                        this.setState({ selectedData: { ...this.state.selectedData, Username: event.target.value } })
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} >

                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedInput fullWidth
                        labelWidth={70}
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.selectedData.Password}
                        onChange={(event) => {
                          this.setState({ selectedData: { ...this.state.selectedData, Password: event.target.value } })
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                            >
                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText >Kosongkan Jika tidak ingin merubah</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} >
                    <TextField
                      select fullWidth
                      label="Instansi"
                      value={this.state.selectedData.InstansiID == null ? 0 : this.state.selectedData.InstansiID}
                      onChange={(event) => {
                        this.setState({ selectedData: { ...this.state.selectedData, InstansiID: event.target.value } })

                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option value={0} >Pilih Instansi</option>
                      {this.state.listInstansi.map((option) => (
                        <option key={option.ID} value={option.ID}>
                          {option.Nama}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                </Grid>
              </form>
            </DialogContent>

            <DialogActions className={classes.dialogAction}>
              <Button
                style={{ marginRight: 10 }}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<CheckIcon />}
                onClick={this.saveButton}
              >{this.state.selectedData.ID == 0 ? "Tambah" : "Ubah"} Data</Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<CancelIcon />}
                onClick={this.clearForm}
              >Batal</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>


      <ConfirmDialog
        title={`Hapus data ` + this.state.selectedData.Nama + "?"}
        open={this.state.tanyaHapus}
        setOpen={(val) => this.setState({ tanyaHapus: val })}
        onConfirm={() => this.hapusData(this.state.selectedData.ID)}
        onClose={() => {
          this.clearForm(false)
        }}
      />
    </React.Fragment>
  }
}



MasterUser.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MasterUser);