import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';

import QrReader from 'react-qr-reader'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { ButtonBase } from '@material-ui/core';
import config from '../../constant/config';
import ReactToPrint from 'react-to-print';
import key from '../../constant/key';
import axios from 'axios';
import Notif from '../../components/Notif';
import MediaQuery from 'react-responsive';

const styles = theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial',
    },
    cardActionDisabled: {
        display: 'block',
        textAlign: 'initial',
        backgroundColor: '#eeeeee'
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
});

class AmbilNomor extends React.Component {

    constructor(props) {
        super(props);

        this.notif = React.createRef();
        this.state = {
            instansiList: [],
            instansiDiproses: 0,
            loadingAmbilAntrian: false,
            antrianDialog: false,
            antrian: {
                ID: 0,
                InstansiID: "dispendukcapil",
                Nomor: "e-123",
                Tanggal: '2020-01-03'
            },
            kodeunik: ""
        }
    }

    _inisialisasiList = () => {
        this.setState({ loadingAmbilAntrian: true })
        const url = `${config.API_URL}/antrian/instansi-list`;
        fetch(url, {
            method: "GET",
            headers: {
                'Authkey': config.AUTHKEY
            },
        })
            .then(res => res.json())
            .then(res => {
                if (res.result) this.setState({ instansiList: res.data, loadingAmbilAntrian: false })
            })
    }

    cetakDanNotifCS = (modelAntrian) => {

        console.log(modelAntrian)

        const { ws } = this.state
        ws.send(JSON.stringify({
            type: key.WS_ANTRIAN_BARU,
            data: modelAntrian
        }))

        this._inisialisasiList()

        this.setState({
            antrianDialog: true,
            antrian: modelAntrian,
            loadingAmbilAntrian: false,
            instansiDiproses: 0
        }, () => {
            this.printer.handlePrint();
        })

        this.timeoutHandle = setTimeout(() => {
            this.setState({ antrianDialog: false })
        }, 3000);

    }
    _ambilNomor = (instansi) => {


        if (instansi.TotalAntrian >= instansi.MaxAntrian) {
            this.notif.current.handleOpen("error", "Batas Antrian untuk " + instansi.Nama + " adalah " + instansi.MaxAntrian + " nomor perhari.<br/>Manfaatkan <strong> -- Antrian Online -- </strong> untuk pengambilan nomor di hari selanjutnya")
            return
        }

        this.setState({ loadingAmbilAntrian: true, instansiDiproses: instansi.ID })
        const url = `${config.API_URL}/antrian/ambil-nomor`;
        var param = new FormData()
        param.append("instansi_id", instansi.ID)
        fetch(url, {
            method: "POST", headers: { 'Authkey': config.AUTHKEY },
            body: param
        })
            .then(res => res.json())
            .then(res => {
                if (res.result) {
                    this.cetakDanNotifCS(res.data)

                } else {
                    this.notif.current.handleOpen("error", res.message)
                    this.setState({ loadingAmbilAntrian: false, })
                }
            }).catch(function (error) {
                this.setState({ loadingAmbilAntrian: false, instansiDiproses: 0 })
                console.log(error);
            });
    }
    _websocketConnect = () => {
        var ws = new WebSocket(config.WS_URL)
        let that = this // cache the this
        var connectInterval

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component")

            this.setState({ ws: ws })

            that.timeout = 250 // reset timer to 250 on open of websocket connection 
            clearTimeout(connectInterval) // clear Interval on on open of websocket connection
        }

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            )

            that.timeout = that.timeout + that.timeout //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)) //call check function after timeout
        }

        ws.onmessage = (e) => {
        }
        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            )

            ws.close()
        }
    }
    componentWillUnmount() {
        // pevent memoryleak
        clearTimeout(this.timeoutHandle);
    }

    componentDidMount() {
        this._websocketConnect()
        this._inisialisasiList()
    }

    pad = (num, size) => {
        var s = "000000000" + num;
        return s.substr(s.length - size);
    }

    cobaCetakOnline = (kodeunik = "") => {
        const url = `${config.API_URL}/antrian/kedatangan/${kodeunik}`;

        axios.get(url, {
            headers: { Authkey: '', }
        }).then(response => {
            var data = response.data
            console.log(data)

            if (data.result) {
                this.cetakDanNotifCS(data.data)
            } else {
                this.notif.current.handleOpen("error", data.message)
            }
            this.setState({ kodeunik: "" })

        })
    }
    handleScan = data => {
        if (data) {
            if (this.state.kodeunik == "")
                this.setState({ kodeunik: data }, () => {
                    this.cobaCetakOnline(data)
                })
        }
    }
    handleErrorScan = () => {
    }

    render() {
        const { classes } = this.props;
        const { instansiDiproses, loadingAmbilAntrian, instansiList, antrianDialog, antrian } = this.state;

        return <React.Fragment>
            <AppBar position="relative">
                <Toolbar>
                    <CameraIcon className={classes.icon} />
                    <Typography variant="h6" color="inherit" noWrap>
                        Mini MPP Kecamatan Sukodono
                </Typography>
                </Toolbar>
            </AppBar>

            <MediaQuery minDeviceWidth={900}>
                <img
                    style={{
                        opacity: 0.5,
                        zIndex: -100,
                        position: "fixed",
                        bottom: 0,
                        left: 20,
                        width: 300
                    }}
                    src="../../../image/drawkit-grape-pack-illustration-17.svg"
                    alt="antrian online Mini MPP Kecamatan Sukodono" />

            </MediaQuery>

            <Notif ref={this.notif} />
            <main>
                <Grid container spacing={3} style={{ padding: 20, marginBottom: 100 }}>

                    <Grid item xs={12} sm={3} md={3}>
                        <Card className={classes.card}>
                            <QrReader
                                delay={300}
                                onError={this.handleErrorScan}
                                onScan={this.handleScan}
                                style={{ width: '100%' }}
                            />
                        </Card>
                    </Grid>
                    <Grid container spacing={2} xs={12} sm={9} md={9}>
                        {instansiList.map((card) => (
                            <Grid item key={card.ID} xs={6} sm={3} md={2}>
                                <Card className={classes.card}>
                                    <ButtonBase
                                        disabled={loadingAmbilAntrian}
                                        className={
                                            instansiDiproses === card.ID && loadingAmbilAntrian ?
                                                classes.cardActionDisabled : classes.cardAction
                                        }
                                        onClick={event => this._ambilNomor(card)}
                                    >
                                        <CardMedia
                                            className={classes.cardMedia}
                                            image={"https://picsum.photos/200/150?blur=2&random=" + card.ID}
                                            title="Image title"
                                        />
                                        <div style={{
                                            position: 'absolute',
                                            color: 'white',
                                            top: 50,
                                            left: '50%',
                                            transform: 'translateX(-50%)'
                                        }} >
                                            <Typography variant="h5" component="h5">
                                                {card.Kode + "-" + this.pad(card.TotalAntrian, 3)}
                                            </Typography>
                                        </div>
                                        <CardContent className={classes.cardContent}>
                                            <Typography variant="p" component="p">
                                                {card.Nama}
                                            </Typography>
                                        </CardContent>
                                    </ButtonBase>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </main>


            <ReactToPrint
                style={{ display: "none" }}
                ref={printer => this.printer = printer}
                trigger={() => <a href="#"></a>}
                content={() => this.componentRef}
            />
            <div
                ref={el => (this.componentRef = el)}>
                <table style={{ width: "100%", textAlign: "center" }}>
                    <tr><td style={{ fontWeight: "bold", fontSize: 18 }}><p style={{ marginBottom: 0 }}>MINI MPP</p></td></tr>
                    <tr><td style={{ fontWeight: "bold", fontSize: 14 }}><p style={{ marginBottom: 0 }}>KECAMATAN SUKODONO</p></td></tr>
                    <tr><td><p style={{ fontSize: 12 }}>Jalan Raya Bukit Kweni</p></td></tr>
                    <tr><td><p style={{ fontSize: 10 }}>Sukodono, Sidoarjo</p></td></tr>
                    <tr><td>________</td></tr>
                    <tr><td><p style={{ fontSize: 16 }}>{antrian.NamaInstansi}</p></td></tr>
                    <tr><td><p style={{ fontSize: 50 }}>
                        {antrian.KodeInstansi + (antrian.Nomor == 0 ? "O" : "") + "-" + this.pad(antrian.Nomor == 0 ? antrian.NomorOnline : antrian.Nomor, 3)}
                    </p></td></tr>
                    <tr><td><p style={{ fontSize: 16 }}>{antrian.Nik}</p></td></tr>
                    <tr><td><p style={{ fontSize: 10 }}>{antrian.JamKedatangan}</p></td></tr>
                    <tr style={{ display: 'none' }}>
                        <td>
                            <img width={100} src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/330px-QR_code_for_mobile_English_Wikipedia.svg.png"></img>
                        </td>
                    </tr>
                    <tr><td></td></tr>
                    <tr><td><br /></td></tr>
                    <tr><td><br /></td></tr>
                </table>
            </div>
            <Dialog
                open={antrianDialog}
                fullWidth
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent >
                    <Typography variant="h5" component="h1" align='center'>
                        Mini MPP
                    </Typography>
                    <Typography variant="h5" component="h1" align='center'>
                        Kecamatan Sukodono
                    </Typography>
                    <Typography variant="subtitle1" component="h2" gutterBottom align='center'>
                        Jalan Raya Bukit Kweni, Sukodono, Sidoarjo
                    </Typography>
                    <Typography variant="h6" component="h3" align='center'>
                        {antrian.NamaInstansi}
                    </Typography>
                    <Typography variant="h2" component="h3" gutterBottom align='center'>
                        {antrian.KodeInstansi + (antrian.Nomor == 0 ? "O" : "") + "-" + this.pad(antrian.Nomor == 0 ? antrian.NomorOnline : antrian.Nomor, 3)}
                    </Typography>
                    <Typography variant="caption" component="h3" align='center'>
                        {antrian.JamKedatangan}
                    </Typography>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    }
}



AmbilNomor.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AmbilNomor);