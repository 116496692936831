import React from 'react';
import { AppBar, Button, Toolbar, Typography, withStyles } from "@material-ui/core"
import { Business, ConfirmationNumber, Description, ExitToApp, People } from "@material-ui/icons"

import PropTypes from 'prop-types';
import key from '../constant/key';
import { withRouter } from 'react-router-dom';

class MainAppbar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            userData: null
        }
    }

    signOut = () => {
        localStorage.removeItem(key.USER_SESSION)
        this.props.history.replace('/')
    }

    componentDidMount() {
        var userData = localStorage.getItem(key.USER_SESSION)
        if (userData != null) {
            this.setState({ userData: JSON.parse(userData) })
        } else {
        }
    }

    render() {

        const { classes } = this.props

        const isAdmin = this.state.userData != null && this.state.userData.RoleID == 1
        console.log(isAdmin)
        return <AppBar position="static">
            <Toolbar>
                <ConfirmationNumber className={classes.icon} />
                <Typography variant="h6" color="inherit" noWrap>
                    Mini MPP
            </Typography>
                <Typography variant="h6" color="inherit" noWrap>
                    Kecamatan Sukodono
            </Typography>
                <div className={classes.toolbarButtons}>
                    <Button color="inherit" startIcon={<People />} onClick={() => { this.props.history.replace('/Pengguna') }} className={classes.btnToolbar}>User</Button>
                    <Button color="inherit" startIcon={<Business />} onClick={() => { this.props.history.replace('/Instansi') }} className={[classes.btnToolbar, isAdmin ? '' : classes.hidden]}>Instansi</Button>
                    <Button color="inherit" startIcon={<Description />} onClick={() => { this.props.history.replace('/Layanan') }} className={classes.btnToolbar}>Layanan</Button>
                    <Button color="inherit" startIcon={<ExitToApp />} onClick={this.signOut} className={classes.btnToolbar}>Keluar</Button>
                </div>
            </Toolbar>
        </AppBar >

    }
}

const styles = theme => ({
    mainContaner: {
        paddingTop: 15,
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    btnToolbar: {
        marginRight: 10,
    },
    hidden: { display: 'none' }

});

MainAppbar.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(withRouter(MainAppbar))