import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
const ConfirmDialog = ({ title, children, open, setOpen, onConfirm, onClose }) => {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={() => setOpen(false)}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="text"
          size="small"
          onClick={() => {
            setOpen(false)
            onClose();
          }}
          color="secondary"
          style={{ marginRight: 30 }}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          style={{ width: 100 }}
          color="primary"
        >
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;