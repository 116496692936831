import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import PropTypes from 'prop-types'
import { fade } from '@material-ui/core/styles/colorManipulator'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import {
    PhotoCamera, VolumeUp, PlayArrow, Stop,
    ConfirmationNumber, SurroundSound, RecordVoiceOver, ExitToApp,
    People
} from '@material-ui/icons'

import config from '../../constant/config'
import key from '../../constant/key'
import { Chip, IconButton } from '@material-ui/core'
import ConfirmDialog from '../../components/ConfirmDialog'

import { terbilang, pisahHurufAngka, suaraAntrian } from '../../Helper/Angka'
import MainAppbar from '../../components/MainAppbar'
import MediaQuery from 'react-responsive'

const styles = theme => ({
    toolbarButtons: {
        marginLeft: 'auto',
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    btnPlay: {
        marginLeft: theme.spacing(1),
    },
    rowSelected: {
        backgroundColor: fade(theme.palette.primary.main, 0.05)
    },
    btnToolbar: {
        marginLeft: 20
    }
})

class PanggilNomor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            userData: {},
            daftarAntrian: [],
            sedangMemanggil: false,
            tanyaMemulai: false,
            tanyaSelesai: false,
            sedangMelayani: false,
            antrianTerpilih: {
                ID: 0,
                InstansiID: "-",
                Nomor: "e-123",
                Tanggal: '2020-01-03'
            }
        }
    }
    _inisialisasiDaftarPanggilan = () => {

        this.setState({ loadingAmbilAntrian: true })
        const url = `${config.API_URL}/antrian/hari-ini`
        fetch(url, {
            method: "GET", headers: { 'Authkey': this.state.userData.Authkey },
        })
            .then(res => res.json())
            .then(res => {
                if (res.result) this.setState({ daftarAntrian: res.data })
            })

    }
    _panggilAntrian = (antrianTerpilih) => {
        this.setState({ sedangMemanggil: true, antrianTerpilih: antrianTerpilih })

        //TODO : Panggil url agar websocket menginfokan ada antrian yang dipanggil
        const { ws } = this.state;
        // const ws = new WebSocket(config.WS_URL)
        if (ws === null) return
        antrianTerpilih.NamaLoket = this.state.userData.Nama
        antrianTerpilih.SuaraLoket = this.state.userData.Suara


        ws.send(JSON.stringify({
            type: key.WS_PANGGILAN,
            data: antrianTerpilih
        }))

        this.timeoutHandle = setTimeout(() => {
            this.setState({ sedangMemanggil: false })
        }, 6000)



    }
    _hapusAntrian(antrian) {
        const { antrianTerpilih } = this.state
        if (antrianTerpilih.ID !== antrian.ID)
            this.setState({
                daftarAntrian: this.state.daftarAntrian.filter(function (obj) {
                    return obj.ID !== antrian.ID
                })
            })
    }
    _tambahAntrian(antrian) {
        var data = this.state.daftarAntrian
        var userData = this.state.userData
        if (antrian.InstansiID == userData.InstansiID) {
            data.push(antrian)
            this.setState({ daftarAntrian: data })
        }
    }

    _mulaiPelayanan = (antrianTerpilih) => {
        antrianTerpilih.JamMulai = new Date()
        antrianTerpilih.Status = 2

        var param = new FormData()
        param.append("antrian_id", antrianTerpilih.ID,)
        fetch(`${config.API_URL}/antrian/mulai`, {
            method: 'POST', body: param,
            headers: { 'Authkey': this.state.userData.Authkey }
        })
            .then(res => res.json())
            .then(res => {
                if (res.result) {
                    const { ws } = this.state
                    ws.send(JSON.stringify({
                        type: key.WS_ANTRIAN_SELESAI,
                        data: antrianTerpilih
                    }))

                    this.setState({ sedangMelayani: true, antrianTerpilih: antrianTerpilih })
                }
            })
            .catch(error => { console.log(error) })

    }
    _selesaiPelayanan = (antrianTerpilih) => {
        antrianTerpilih.JamSelesai = new Date()
        antrianTerpilih.Status = 3

        //kontak ke server bahwa sudah selesai layanan, untuk update ke cs sebelah
        //pakai websocket.
        var param = new FormData()
        param.append("antrian_id", antrianTerpilih.ID,)
        fetch(`${config.API_URL}/antrian/selesai`, {
            method: 'POST', body: param,
            headers: { 'Authkey': this.state.userData.Authkey }
        })
            .then(res => res.json())
            .then(res => {
                if (res.result) {
                    this.setState({
                        sedangMelayani: false,
                        daftarAntrian: this.state.daftarAntrian.filter(function (obj) {
                            return obj.ID !== antrianTerpilih.ID
                        }),
                        antrianTerpilih: {
                            ID: 0, InstansiID: "", Nomor: "", Tanggal: ''
                        },

                    })
                }
            })
            .catch(error => { console.log(error) })

    }
    _websocketConnect = () => {
        var ws = new WebSocket(config.WS_URL)
        let that = this // cache the this
        var connectInterval

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component")

            this.setState({ ws: ws })

            that.timeout = 250 // reset timer to 250 on open of websocket connection 
            clearTimeout(connectInterval) // clear Interval on on open of websocket connection
        }

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            )

            that.timeout = that.timeout + that.timeout //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)) //call check function after timeout
        }

        ws.onmessage = (e) => {
            const message = JSON.parse(e.data)
            console.log("receiving")
            console.log(message)


            if (message.type === key.WS_ANTRIAN_BARU)
                this._tambahAntrian(message.data)
            else if (message.type === key.WS_ANTRIAN_SELESAI)
                this._hapusAntrian(message.data)

        }
        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            )

            ws.close()
        }
    }
    componentWillUnmount() {
        // pevent memoryleak
        clearTimeout(this.timeoutHandle)
    }

    componentDidMount() {

        this._websocketConnect()
        var userData = localStorage.getItem(key.USER_SESSION)
        if (userData != null) {
            this.setState(
                { userData: JSON.parse(userData) },
                () => this._inisialisasiDaftarPanggilan()
            )
        } else {
            this.props.history.push('/')
        }
    }


    pad = (num, size) => {
        var s = "000000000" + num
        return s.substr(s.length - size)
    }
    jam = (stringDatetime) => {

        if (stringDatetime == null) return "-"

        var s = stringDatetime.split(" ")[1] //handle di hp

        if (s !== undefined) return s

        var dt = new Date(stringDatetime.replace(' ', 'T'))
        return dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds()
    }

    render() {
        const { classes } = this.props
        const { userData, sedangMemanggil, antrianTerpilih, daftarAntrian, sedangMelayani } = this.state

        return <React.Fragment>
            <MainAppbar />

            <MediaQuery minDeviceWidth={900}>
                <img
                    style={{
                        zIndex: -100,
                        position: "fixed",
                        bottom: 0,
                        right: 20,
                        marginRight: -100,
                        width: 300
                    }}
                    src="../../../image/drawkit-grape-pack-illustration-16.svg"
                    alt="antrian online Mini MPP Kecamatan Sukodono" />

                <img
                    style={{
                        zIndex: -100,
                        position: "fixed",
                        bottom: 0,
                        marginLeft: -10,
                        width: 290
                    }}
                    src="../../../image/drawkit-grape-pack-illustration-5.svg"
                    alt="antrian online Mini MPP Kecamatan Sukodono" />
            </MediaQuery>
            <main>
                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={3} justify={"center"}>
                        <Grid item xs={12} sm={8} md={8}>

                            <Typography variant="h6" component="h1" align='left' gutterBottom>
                                {userData.NamaInstansi} Loket {userData.Nama}
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nomor</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {daftarAntrian.map((item) => (
                                            <TableRow key={item.ID} className={antrianTerpilih.ID === item.ID ? classes.rowSelected : null}>
                                                <TableCell component="th" scope="row">
                                                    {item.KodeInstansi + (item.Nomor == 0 ? "O" : "") + "-" + this.pad(item.Nomor == 0 ? item.NomorOnline : item.Nomor, 3)}
                                                    &nbsp;&nbsp;
                                                    {item.Nomor == 0 ? <Chip
                                                        size="small"
                                                        label="online"
                                                        color="secondary"
                                                    /> : <div />}
                                                </TableCell>
                                                <TableCell align="right">{this.jam(item.JamKedatangan)}</TableCell>
                                                <TableCell align="right">
                                                    {!sedangMemanggil && !sedangMelayani &&
                                                        <IconButton onClick={() => this._panggilAntrian(item)} color="primary" aria-label="Panggil Antrian" className={classes.btnPlay}>
                                                            <VolumeUp fontSize="small" />
                                                        </IconButton>
                                                    }

                                                    {!sedangMelayani && <IconButton onClick={() => this.setState({ antrianTerpilih: item, tanyaMemulai: true })} color="primary" aria-label="Mulai Pelayanan" className={classes.btnPlay}>
                                                        <PlayArrow fontSize="small" />
                                                    </IconButton>
                                                    }

                                                    {sedangMelayani && antrianTerpilih.ID === item.ID && <IconButton onClick={() => this.setState({ antrianTerpilih: item, tanyaSelesai: true })} color="secondary" aria-label="Selesai Pelayanan" className={classes.btnPlay}>
                                                        <Stop fontSize="small" />
                                                    </IconButton>
                                                    }

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {daftarAntrian.length === 0 && <TableRow>
                                            <TableCell align="center" colSpan={3}>Tidak ada data</TableCell>
                                        </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>
            </main>


            <Dialog
                open={sedangMemanggil}
                fullWidth
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography variant="h5" component="h1" align='center'>
                        Mini MPP Sidoarjo
                    </Typography>
                    <Typography variant="subtitle1" component="h2" gutterBottom align='center'>
                        Jalan Raya Bukit Kweni, Sukodono, Sidoarjo
                    </Typography>
                    <Typography variant="h6" component="h3" align='center'>
                        {"Loket " + userData.Nama}
                    </Typography>
                    <Typography variant="h2" component="h3" gutterBottom align='center'>
                        {antrianTerpilih.KodeInstansi + (antrianTerpilih.Nomor == 0 ? "O" : "") + "-" + this.pad(antrianTerpilih.Nomor == 0 ? antrianTerpilih.NomorOnline : antrianTerpilih.Nomor, 3)}
                    </Typography>
                    <Typography variant="caption" component="h3" align='center'>
                        {antrianTerpilih.JamKedatangan}
                    </Typography>
                </DialogContent>
            </Dialog>

            <ConfirmDialog
                title={`Memulai Pelayanan ` + antrianTerpilih.KodeInstansi + "-" + this.pad(antrianTerpilih.Nomor, 3) + "?"}
                open={this.state.tanyaMemulai}
                onClose={() => () => { }}
                setOpen={(val) => this.setState({ tanyaMemulai: val })}
                onConfirm={() => this._mulaiPelayanan(antrianTerpilih)}
            />

            <ConfirmDialog
                title={`Mengakhiri Pelayanan ` + antrianTerpilih.KodeInstansi + "-" + this.pad(antrianTerpilih.Nomor, 3) + "?"}
                open={this.state.tanyaSelesai}
                setOpen={(val) => this.setState({ tanyaSelesai: val })}
                onConfirm={() => this._selesaiPelayanan(antrianTerpilih)}
            />
        </React.Fragment >
    }
}



PanggilNomor.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(PanggilNomor)