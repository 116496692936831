import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";


import AmbilNomor from './Page/Public/AmbilNomor';
import Pemanggil from './Page/Public/Pemanggil';
import Example from './Page/Public/Example';
import SignIn from './Page/Public/SignIn';
import PanggilNomor from './Page/Private/PanggilNomor';
import MasterUser from './Page/Private/MasterUser';
import MasterInstansi from './Page/Private/MasterInstansi';
import PesanNomor from './Page/Public/PesanNomor';
import MasterLayanan from './Page/Private/MasterLayanan';
import DisplayQrcode from './Page/Public/DisplayQrcode';

function App() {
  return (
    <Router path={'/'} basename={'/'}>
      <Switch>
        <Route path="/Pemanggil" component={Pemanggil} />
        <Route path="/AmbilNomor" component={AmbilNomor} />
        <Route path="/Example" component={Example} />
        <Route path="/PanggilNomor" component={PanggilNomor} />
        <Route path="/Pengguna" component={MasterUser} />
        <Route path="/Instansi" component={MasterInstansi} />
        <Route path="/Layanan" component={MasterLayanan} />
        <Route path="/PesanNomor" exact component={PesanNomor} />
        <Route path="/DisplayQrcode/:kodeunik" exact component={DisplayQrcode} />
        <Route path="/" exact component={SignIn} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
}

export default App;
